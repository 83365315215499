import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

export default function MicErrorsModal({ children, onOk }) {
  return (
    <Modal title="Action Needed: Microphone Access" onOk={onOk} okBtnTxt="Ok">
      {children}
    </Modal>
  );
}

MicErrorsModal.propTypes = {
  children: PropTypes.node.isRequired,
  onOk: PropTypes.func.isRequired,
};
