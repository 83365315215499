/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import MicErrorsModal from './components/MicErrorsModal/MicErrorsModal';
import { useRootStore } from './store/RootStateContext';
import { routes, routesWithoutSettings, authRoutes } from './routes';
import layouts from './layouts';
import { I18nProvider } from './i18n';
import DocumentHead from './components/DocumentHead/DocumentHead';
import SocketsErrorModal from './components/SocketsErrorModal/SocketsErrorModal';

const App = ({ history }) => {
  const { authStore, errorsStore } = useRootStore();

  const [isMicErrorModalShown, setIsMicErrorModalShown] = useState(false);
  const [micErrorText, setMicErrorText] = useState('');

  useEffect(() => {
    async function checkMicAccess() {
      try {
        // Check if the user has granted permission to use the microphone
        const permissionStatus = await navigator.permissions.query({
          name: 'microphone',
        });

        if (permissionStatus.state === 'prompt') {
          await navigator.mediaDevices.getUserMedia({ audio: true });
        }

        if (permissionStatus.state === 'denied') {
          setMicErrorText(
            'Microphone access is denied. Please enable it in your browser settings.'
          );
          setIsMicErrorModalShown(true);
          return;
        }

        if (!permissionStatus.state === 'granted') {
          setMicErrorText(
            'Microphone access permission is not yet determined. Please check your browser settings.'
          );
          setIsMicErrorModalShown(true);
          return;
        }
      } catch (error) {
        setMicErrorText('An error occurred while checking microphone access.');
        setIsMicErrorModalShown(true);
      }
    }

    checkMicAccess();
  }, []);

  const renderRoutes = ({
    routesList,
    condition,
    redirectPath,
    layoutName,
  }) => {
    const Layout = layouts[layoutName];

    return routesList.map(({ Component, ...rest }) => (
      <Route
        key={rest.path}
        {...rest}
        render={({ location }) =>
          condition ? (
            <Layout>
              <Component />
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname: redirectPath,
                state: { from: location },
              }}
            />
          )
        }
      />
    ));
  };

  return (
    <I18nProvider locale={authStore.lang}>
      <>
        <DocumentHead />
        {isMicErrorModalShown && (
          <MicErrorsModal onOk={() => setIsMicErrorModalShown(false)}>
            {micErrorText}
          </MicErrorsModal>
        )}
        {errorsStore.isSocketsErrorShown && <SocketsErrorModal />}
        <Router history={history}>
          <Switch>
            {renderRoutes({
              routesList: authRoutes,
              condition: !authStore.authUser,
              redirectPath: '/app/dashboard',
              layoutName: 'AuthLayout',
            })}
            {renderRoutes({
              routesList: authStore.isSecurity ? routes : routesWithoutSettings,
              condition: authStore.authUser,
              redirectPath: '/signin',
              layoutName: 'MainLayout',
            })}
            <Route path="*">
              <Redirect to="/app/dashboard" />
            </Route>
          </Switch>
        </Router>
      </>
    </I18nProvider>
  );
};

export default observer(App);
